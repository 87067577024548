import { useState, useEffect, ReactNode } from "react"
import { APITokens, UserContextType, BootstrapVariants, AlertDefinition, Page } from "./types"
import { NavBar } from "./nav_bar/nav_bar"
import { UserContext,  } from "./context"
import { Register } from "./auth/register"
import { useLocalStorage } from "./useLocalStorage"
import { useCurrentUser } from "./api_v1/auth"
import { axios_backend } from "./api_v1/axios_backend"
import { match, select } from "ts-pattern"
import { UserTravel } from "./user_travel/user_travel"
import { Admin } from "./admin/admin"

import "./common.scss"
import { Alert, Container, Spinner } from "react-bootstrap"

interface HRPortalProps {
}

const api_tokens_key = "ea90c0c2a4f39246";

export const HRPortal = (props: HRPortalProps) => { 
    const [alerts, set_alerts] = useState<{content: ReactNode, variant: BootstrapVariants}[]>([])
    const [api_tokens, set_api_tokens] = useLocalStorage<APITokens | null>(api_tokens_key, null)

    const {isLoading, isSuccess, data: current_user } = useCurrentUser(api_tokens?.refresh ?? null)
    

    const [ page, set_page ] = useState<Page>({page: "home"})

    const user_context: UserContextType = {
        user: (isSuccess && (current_user != null)) ? current_user : null,
        api_tokens: api_tokens,
        onUserLogin: (api_tokens: APITokens) => {
            set_api_tokens(api_tokens);  
        },
        onUserLogout: () => {
            user_context.onAlert({content: "Logged out", variant: "primary"})
            set_api_tokens(null);
        },
        onTokenRefresh: ()=>{},
        onAlert: (alert: AlertDefinition) => {
            set_alerts([...alerts, alert])
        },
        onGotoPage: (page) => {
            set_page(page);
        }
    };

    useEffect(() => {
        if (api_tokens == null) {
            if ('Authorization' in axios_backend.defaults.headers.common) {
                delete axios_backend.defaults.headers.common['Authorization'];
            }
        } else {
            axios_backend.defaults.headers.common['Authorization'] = `Bearer ${api_tokens.access}`;
        }        
    }, [api_tokens])

    const remove_alert = (idx: number) => {
        let new_alerts = [...alerts];
        new_alerts.splice(idx, 1)
        set_alerts(new_alerts);
    }

    return (
        <UserContext.Provider value={user_context}>
            <div>
                <NavBar/>                
                <Container className="pt-2">
                    {
                        alerts.map((alert: AlertDefinition, idx) => (
                            <Alert variant={alert.variant} onClose={() => {remove_alert(idx)}} dismissible>
                                {alert.content}
                            </Alert>
                        ))
                    }

                    { isLoading ?
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    :
                        match(page)
                            .with({ page: "home"}, () => (
                                <div className="jumbotron">
                                    <div>
                                        { user_context.user == null ? <>
                                            <h1 className="display-4">Hello!!</h1>
                                            <p className="lead">Welcome to the Delft Circuits travel log portal</p>
                                            <hr className="my-4"/>
                                            <p>Login to start or register if you don't have an account yet</p>
                                            <p className="lead">
                                                <Register/>
                                            </p>
                                        </>
                                        : <>
                                            <h1 className="display-4">Hello {user_context.user.first_name} !!</h1>
                                        </> }                                            
                                    </div>                        
                                </div>
                            ))

                            .with({page: "user_travel"}, () => {
                                if (user_context.user) {
                                    return <UserTravel user_id={user_context.user.id}/>
                                } else {
                                    user_context.onGotoPage({ page: "home"});
                                    return "";
                                }
                            })

                            .with({ page: "admin", sub: select() }, (sub) => (
                                <Admin page={sub}/>
                            ))

                            .exhaustive()
                            

                    }
                </Container>
                
            </div>
        </UserContext.Provider>        
    )
};
