import { useState } from "react"
import { Alert, Form, Spinner } from "react-bootstrap"
import { useUsers } from "../api_v1/auth"
import { UserTravel } from "../user_travel/user_travel"


export interface AdminUserTravelProps {

}

export const AdminUserTravel = (props: AdminUserTravelProps) => {
    // const user_context = useContext(UserContext);
    const { isLoading: isUsersLoading, 
            isSuccess: isUsersSuccess,
            isError: isUsersError, 
            error: users_error,
            data: users } = useUsers()

    const [user_id, set_user_id] = useState<number | null>(null);

    if (isUsersLoading) {
        <div className="text-center"><Spinner animation="border"></Spinner></div>
    }

    if (isUsersError && (users_error != null)) {
        return (
            <Alert variant="danger">
                <Alert.Heading>Error!</Alert.Heading>
                <p>{ users_error.message }</p>
            </Alert>
        )
    }

    if (isUsersSuccess && (users != null)) {
        const handle_on_change_user_select = (event: React.ChangeEvent<HTMLSelectElement>) => {
            const user = users[parseInt(event.target.value)];
            set_user_id(user.id);
        }
        
        
        return (<>
            <h3>User travel log</h3>
            <Form>
                <Form.Group className="mb-3">
                    <Form.Select placeholder="Select user" onChange={handle_on_change_user_select}>
                        {
                            users.map((user, idx) => (
                                <option key={user.id} value={idx}>{user.first_name} {user.last_name}</option>
                            ))
                        }
                    </Form.Select>
                </Form.Group>
            </Form>
            { user_id ? <UserTravel user_id={user_id}/> : "" }
            
        </>)
    }

    return <></>
    
}