import { useContext } from "react"
import { Alert, Table, Button, Spinner } from "react-bootstrap"
import { UserContext } from "../context"
import { useTravelReports, useTravelReportsMutation } from "../api_v1/travel"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import "./admin_reports.scss"


export interface AdmiTravelReportsProps {

}

export const AdminTravelReports = (props: AdmiTravelReportsProps) => {
    const user_context = useContext(UserContext);
    const { isLoading, isError, error, isSuccess, data: reports} = useTravelReports();
    const { mutate } = useTravelReportsMutation();

    const handle_add_report = () => {
        mutate({
            mutation_type: "add",
            end_date: new Date(),
        })
    };

    return (
        <>            
            <h3>Travel reports</h3>
            { isLoading ? 
                 <div className="text-center"><Spinner animation="border"></Spinner></div> : <></>
            }

            { isError && (error != null) ?
                <Alert variant="danger">
                    <Alert.Heading>Error!</Alert.Heading>
                    <p>{ error.message }</p>
                </Alert>
                : <></>
            }

            { isSuccess && (reports != null) ? 
                <Table striped hover>
                    <thead>
                        <tr>
                            <th className="admin-travel-reports-date-col">Start date</th>
                            <th className="admin-travel-reports-date-col">End date</th>
                            <th>State</th>
                            <th>Updates</th>
                        </tr>                
                    </thead>
                    <tbody>
                        <tr>
                            <th>
                                <Button size="sm" variant="secondary" 
                                    onClick={handle_add_report}
                                    >
                                    <FontAwesomeIcon icon={faPlus}/>
                                </Button> 
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        { reports.reverse().map((report) => (
                            <tr className="admin-travel-reports-row" key={report.id}
                                onClick={() => {user_context.onGotoPage({page: "admin", sub: ["travel_report", report]})}}
                                >
                                <th>{ report.start_date.toDateString ()}</th>
                                <th>{ report.end_date.toDateString ()}</th>
                                <td>{ report.state }</td>
                                <td>{ report.updated_count ?
                                    <span className="text-danger">
                                        <FontAwesomeIcon icon={faExclamationTriangle}/> &nbsp;
                                        { report.updated_count }
                                    </span>
                                    : <></>
                                }</td>
                                
                            </tr>
                        )) }
                    </tbody>
                </Table>
            : <></> }
            
        </>
        
    )
}