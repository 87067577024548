import { useState, useEffect } from "react"
import {Popover, OverlayTrigger, Button, Col, Row, Container} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import "./month_select.scss"

export type Month = 1|2|3|4|5|6|7|8|9|10|11|12;

export type MonthYear = {
    month: Month
    year: number
}

export interface MonthSelectProps {
    initial: MonthYear
    onChange?: (month: MonthYear) => void
}

const month_button_class = (idx: number, idx_selected: number) => {
    // return "month-select-month-button";
    if (idx === idx_selected) {
        return "primary";
    } else {
        return "outline-secondary";
    }
}

const months = [
    null,
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
]

export const MonthSelect = (props: MonthSelectProps) => {
    const [value, set_value] = useState<MonthYear>({month: props.initial.month, year: props.initial.year});

    const handle_change = (month: Month, year: number) => {
        const new_value = {month: month, year: year};
        set_value(new_value);
        if (props.onChange) {
            props.onChange(new_value)
        }        
    }

    const popover = (
        <Popover id="popover-basic">
          <Popover.Body>
                <Container className="month-select">
                    <Row>
                        <Col sm="auto" className="month-select-year-button">
                            <FontAwesomeIcon icon={faAngleDoubleLeft} onClick={()=>{handle_change(value.month, value.year-1)}}/>                      
                        </Col>
                        <Col className="month-select-year">
                            {value.year}
                        </Col>
                        <Col sm="auto" className="month-select-year-button">
                            <FontAwesomeIcon icon={faAngleDoubleRight} onClick={()=>{handle_change(value.month, value.year+1)}}/>                      
                        </Col>
                    </Row>
                    <Row>
                        <Col className="month-select-month-button"><Button size="sm" onClick={() => {handle_change(1, value.year)}} variant={month_button_class(1, value.month)}>Jan</Button></Col>
                        <Col className="month-select-month-button"><Button size="sm" onClick={() => {handle_change(2, value.year)}} variant={month_button_class(2, value.month)}>Feb</Button></Col>
                        <Col className="month-select-month-button"><Button size="sm" onClick={() => {handle_change(3, value.year)}} variant={month_button_class(3, value.month)}>Mar</Button></Col>
                    </Row>
                    <Row>
                        <Col className="month-select-month-button"><Button size="sm" onClick={() => {handle_change(4, value.year)}} variant={month_button_class(4, value.month)}>Apr</Button></Col>
                        <Col className="month-select-month-button"><Button size="sm" onClick={() => {handle_change(5, value.year)}} variant={month_button_class(5, value.month)}>May</Button></Col>
                        <Col className="month-select-month-button"><Button size="sm" onClick={() => {handle_change(6, value.year)}} variant={month_button_class(6, value.month)}>Jun</Button></Col>
                    </Row>
                    <Row>
                        <Col className="month-select-month-button"><Button size="sm" onClick={() => {handle_change(7, value.year)}} variant={month_button_class(7, value.month)}>Jul</Button></Col>
                        <Col className="month-select-month-button"><Button size="sm" onClick={() => {handle_change(8, value.year)}} variant={month_button_class(8, value.month)}>Aug</Button></Col>
                        <Col className="month-select-month-button"><Button size="sm" onClick={() => {handle_change(9, value.year)}} variant={month_button_class(9, value.month)}>Sep</Button></Col>
                    </Row>
                    <Row>
                        <Col className="month-select-month-button"><Button size="sm" onClick={() => {handle_change(10, value.year)}} variant={month_button_class(10, value.month)}>Oct</Button></Col>
                        <Col className="month-select-month-button"><Button size="sm" onClick={() => {handle_change(11, value.year)}} variant={month_button_class(11, value.month)}>Nov</Button></Col>
                        <Col className="month-select-month-button"><Button size="sm" onClick={() => {handle_change(12, value.year)}} variant={month_button_class(12, value.month)}>Dec</Button></Col>
                    </Row>
                </Container>
              
          </Popover.Body>
        </Popover>
    );


    return (
        <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
            <Button className="month-select-button border" variant="outline-secondary">{months[value.month]} {value.year}</Button>
        </OverlayTrigger>
    )
}