import { useContext } from "react"
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap"
import { UserContext } from "../context"
import { Page } from "../types"
import { NavBarUserItem } from "./nav_bar_user_item"

interface NavBarProps  {
}

export const NavBar = (props: NavBarProps) => {
    const user_context = useContext(UserContext);

    return (
        <Navbar bg="dark" variant="dark" expand="sm">
            <Container>
                <Navbar.Brand style={{cursor: "pointer"}}
                              onClick={() => {user_context.onGotoPage({page: "home"})}} className="p">Delft Circuits</Navbar.Brand>
                <Navbar.Toggle/>
                <Navbar.Collapse>
                    <Nav className="me-0" style={{minWidth: "100%"}}>
                        <Nav.Item>
                            <Nav.Link onClick={() => {user_context.onGotoPage({page: "user_travel"})}}>Travel log</Nav.Link>
                        </Nav.Item>

                        { user_context.user?.is_staff ? 
                            <NavDropdown title="Admin">
                                <NavDropdown.Item onClick={() => {user_context.onGotoPage({page: "admin", sub: "user_travel"})}}>
                                    User travel log
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => {user_context.onGotoPage({page: "admin", sub: "travel_reports"})}}>
                                    Travel reports
                                </NavDropdown.Item>
                            </NavDropdown>
                            : ""
                        }
                        <NavBarUserItem/>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
};
