import React, { useState, useContext } from "react"
import { Table, Button, Form, Alert, Spinner } from "react-bootstrap"
import { UserTravelConfig } from "../types"
import { UserContext } from "../context"
import { useUser } from "../api_v1/auth"
import { useUserTravelConfig, useTravelConfigMutation } from "../api_v1/travel"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import strftime from "strftime"
import "./travel_config_table.scss"

export interface TravelConfigTableProps {
    user_id: number
}

export const TravelConfigTable = ({ user_id }: TravelConfigTableProps) => {
    const user_context = useContext(UserContext);
    const [ editing_config, set_editing_config ] = useState<{
        config: UserTravelConfig,
        date: Date,
        distance_km: number,
        fte: number
    } | null>(null)

    const is_readonly: boolean = (!user_context.user?.is_staff ?? true);

    const { 
        isLoading: isUserLoading, 
        isSuccess: isUserSuccess, 
        data: user} = useUser(user_id);
    
    const { isLoading: isUserTravelConfigLoading, 
            isError, 
            error, 
            isSuccess: isUserTravelConfigSuccess, 
            data: user_travel_config} = useUserTravelConfig(user_id);
    
    const { mutate } = useTravelConfigMutation()

    const isLoading = isUserLoading || isUserTravelConfigLoading;
    const isSuccess = isUserSuccess && isUserTravelConfigSuccess;

    const handle_edit = (config: UserTravelConfig) => {
        set_editing_config({
            config: config,
            date: config.date,
            distance_km: config.distance_km,
            fte: config.fte
        })
    }

    const handle_apply_edit = () => {
        if ((editing_config != null) && user) {
            mutate({
                mutation_type: "edit",
                user: user,
                config: editing_config.config,
                date: editing_config.date,
                distance_km: editing_config.distance_km,
                fte: editing_config.fte,
            });
            set_editing_config(null)
        }
    }

    const set_value = (event: React.BaseSyntheticEvent) => {
        if (editing_config) {
            const value = event.target.value;
            const name = event.target.name;
            if (name === "date") {
                set_editing_config({
                    ...editing_config,
                    date: new Date(value),
                })
            } else if (name === "fte") {
                set_editing_config({
                    ...editing_config,
                    [name]: parseFloat(value) / 5,
                })
            } else {
                set_editing_config({
                    ...editing_config,
                    [name]: parseFloat(value),
                })
            }
        }
    }

    const handle_delete = (idx: number) => {
        if (isSuccess && user && user_travel_config) {
            mutate({
                mutation_type: "delete",
                user: user,
                config: user_travel_config[idx]
            })
        }      
    }

    const handle_add = () => {
        if (isUserSuccess && user) {
            mutate({
                mutation_type: "add",
                user: user,
                date: new Date(),
                distance_km: 0
            })
        }
    }

    if (isError && (error != null)) {
        return (
            <Alert variant="danger">
                <Alert.Heading>Error!</Alert.Heading>
                <p>{ error.message }</p>
            </Alert>
        )
    }

    return (
        <Table striped>
            <thead>
                <tr>
                    { is_readonly ? "" : <th>Actions</th> }
                    
                    <th>Start date</th>
                    <th>One way [km]</th>
                    <th>Expected travel days per week</th>
                </tr>
            </thead>
            <tbody>
                { isLoading ? 
                    <tr>
                        <td>
                            <div className="text-center"><Spinner animation="border"></Spinner></div>
                        </td>
                    </tr>                    
                : <></>}

                { isSuccess && user_travel_config ? 
                    <>
                        { user_travel_config.map((config, idx) => (
                            <tr key={config.id}>
                                { !is_readonly && (editing_config?.config.id === config.id) ? 
                                    <>                                        
                                        <th>
                                            <Button  
                                                    size="sm" 
                                                    variant="secondary" 
                                                    className="mx-1"
                                                    onClick={handle_apply_edit}>
                                                <FontAwesomeIcon icon={faSave}/>
                                            </Button>
                                            <Button 
                                                    size="sm"
                                                    variant="secondary" 
                                                    className="mx-1"
                                                    onClick={() => { set_editing_config(null) }}>
                                                <FontAwesomeIcon icon={faTimes}/>
                                            </Button>                                            
                                        </th>
                                        <th>
                                            <Form.Control size="sm" name="date" type="date" 
                                                          value={strftime("%Y-%m-%d", editing_config.date)}
                                                          onChange={set_value}
                                                          ></Form.Control>
                                        </th>
                                        <td>
                                            <Form.Control size="sm" name="distance_km" type="number" min={0} step={0.01} 
                                                          value={editing_config.distance_km}
                                                          onChange={set_value}
                                                          ></Form.Control>                                                            
                                        </td>
                                        <td>
                                            <Form.Control size="sm" name="fte" type="number" min={0} step={0.01} 
                                                          value={editing_config.fte * 5}
                                                          onChange={set_value}
                                                          ></Form.Control>
                                        </td>
                                    </>
                                : 
                                    <>
                                        { is_readonly ? "" :
                                            <th>
                                                <Button size="sm" variant="secondary" onClick={() => { handle_edit(config) }}>
                                                    <FontAwesomeIcon icon={faEdit}/>
                                                </Button>
                                                <Button size="sm" variant="secondary" className="mx-1" 
                                                    onClick={() => { handle_delete(idx) }}
                                                    >
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>
                                            </th>
                                        }
                                        <th className="travel-config-table-date-col">{config.date.toDateString()}</th>
                                        <td>{ config.distance_km }</td>
                                        <td>{ config.fte * 5}</td>
                                    </>                                    
                                }              

                            </tr>
                        ))}
        
                        { is_readonly ? "" :
                            <tr>
                                <th>
                                <Button size="sm" variant="secondary">
                                    <FontAwesomeIcon icon={faPlus} 
                                    onClick={handle_add}
                                    />
                                </Button> 
                                </th>
                                <th></th><td></td><td></td>
                            </tr>
                        }
                    </>
                : <></>}
                
            </tbody>
        </Table>
    )
}
