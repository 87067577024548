import { useState, useEffect, useContext } from "react"
import { Alert, Form } from "react-bootstrap"
import { TravelReport } from "../types" 
import { UserContext } from "../context"
import { match, select } from "ts-pattern"

import { AdminUserTravel } from "./admin_user_travel"
import { AdminTravelReports } from "./admin_reports"
import { AdminReport } from "./admin_report"


export interface AdminProps {
    page: "user_travel" | "travel_reports" | ["travel_report", TravelReport]
}

export const Admin = (props: AdminProps) => {
    const user_context = useContext(UserContext);

    if (user_context.user == null || (!user_context.user?.is_staff)) {
        return <Alert variant="danger">
            <Alert.Heading>Unauthorized</Alert.Heading>
            <p>Please log in using an administrator account.</p>
        </Alert>
    }

    return (
        <>
            { match(props.page)
                .with("user_travel", () => <AdminUserTravel/>)
                .with("travel_reports", () => <AdminTravelReports/>)
                .with(["travel_report", select()], (report) => <AdminReport report={report}/>)
                .exhaustive()
            }
        </>
        
        
    )
}
