import { useState, useEffect } from "react";

// source: https://blog.logrocket.com/using-localstorage-react-hooks/

function getStorageValue<Type>(key: string, defaultValue: Type) {
  const saved = localStorage.getItem(key);
  if (saved != null) {
    const initial = JSON.parse(saved);
    return initial;
  } else {
    return defaultValue;
  }
}

export const useLocalStorage = <Type>(key: string, defaultValue: Type): [Type, (value: Type) => void] => {
    const [value, setValue] = useState(() => {
        return getStorageValue(key, defaultValue);
    });

    useEffect(() => {
        // storing input name
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};
