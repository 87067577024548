import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query';
import { axios_backend } from "./axios_backend";
import {  User, APITokens } from "../types"


export const useUsers = (options?: UseQueryOptions<User[], Error>) => {
    return useQuery<User[], Error>("users", async () => {
        const { data } = await axios_backend.get("/v1/users.json");
        return data;
    }, options)
}

export const useUser = (user_id: number | null, options?: UseQueryOptions<User | null, Error>) => {
    return useQuery<User | null, Error>(['user', user_id], async () => {
        if (user_id == null) {
            return null;
        } else {
            const { data } = await axios_backend.get(`/v1/users/${user_id}.json`);
            return data;
        }        
    }, options)
}

export const useCurrentUser = (refresh_token: string | null, options?: UseQueryOptions<User | null, Error>) => {
    return useQuery<User | null, Error>(['current_user', refresh_token], async () => {
        if (refresh_token == null) {
            return null;
        } else {
            const { data } = await axios_backend.get(`/v1/auth/current_user/`);
            return data;
        }        
    }, options)
}

type UseUserRegistrationError = { 
    username?: string[]
    email?: string[]
    password?: string[]
    password2?: string[]
    first_name?: string[]
    last_name?: string[]
    message: string
};

type UseUserRegistrationVariables = { username: string, email: string, password: string,
                                      password2: string, first_name: string, last_name: string }

export const useUserRegistration = (options?: UseMutationOptions<User, UseUserRegistrationError, UseUserRegistrationVariables>) => {
    const result = useMutation<User, UseUserRegistrationError, UseUserRegistrationVariables>(
        async (params: UseUserRegistrationVariables) => {
            const { data } = await axios_backend.post('/v1/auth/register/', params);
            if (typeof data?.username === 'string') {
                return data;
            } else {
                data.message = data?.detail ?? "";
                throw (data)
            }
    }, options)
    
    return result;
}


type useUserLoginError = { 
    email?: string[], 
    password?: string[], 
    message?: string
};

type useUserLoginVariables = {
    email: string, 
    password: string
}

export const useUserLogin = (options?: UseMutationOptions<APITokens, useUserLoginError, useUserLoginVariables>) => {
    const result = useMutation<APITokens, useUserLoginError, useUserLoginVariables>(
        async (params: useUserLoginVariables) => {
            const { data } = await axios_backend.post('/v1/auth/token/', params);
            if (typeof data?.user !== undefined) {
                return data.api_tokens;
            } else {
                data.message = data?.detail ?? "";
                throw (data)
            }
    }, options)
    
    return result;
}