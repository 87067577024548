import { ReactNode, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { BootstrapVariants } from "../types"

export interface ConfirmButtonProps {
    variant: BootstrapVariants
    children: ReactNode
    onConfirm: () => void
}

export const ConfirmButton = (props: ConfirmButtonProps) => {
    const [show, setShow] = useState(false);

    return (
        <>
            <Button variant={props.variant} onClick={() => {setShow(true)} }>{props.children}</Button>

            <Modal show={show} onHide={() => { setShow(false) }}>
                <Modal.Body>Are you sure?</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={() => { props.onConfirm(); setShow(false) }}>
                    Yes
                </Button>
                <Button variant="secondary" onClick={() => { setShow(false) }}>
                    No
                </Button>
                </Modal.Footer>
            </Modal>
        </>
        
        
    )
}