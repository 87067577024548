import { useQuery, useMutation, UseQueryOptions, UseMutationOptions, useQueryClient  } from 'react-query';
import { axios_backend } from "./axios_backend";

import { TravelEntry, UserTravelConfig, User, TravelReport, TravelReportOverview } from "../types"
import strftime from "strftime"


const date_to_string = (date: Date): string => {
    return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
}


// React query hooks
// =================

export const useUserTravel = (user_id: number, start: Date, end: Date, options?: UseQueryOptions<TravelEntry[], Error>) => {
    return useQuery<TravelEntry[], Error>(["users_travel", user_id, start, end], async () => {
        const { data } = await axios_backend.get(`/v1/users/travel/${user_id}`, {
            params: {
                start: start.toISOString(),
                end: end.toISOString()
            }
        });
        return data.map((entry: any): TravelEntry => ({...entry, date: new Date(entry.date)}));
    }, options)
}

type useUserTravelMutateVariables = {
    user_id: number,
    date: Date
    traveled: boolean
}

export const useUserTravelMutation = (options?: UseMutationOptions<TravelEntry[], Error, useUserTravelMutateVariables>) => {
    const queryClient = useQueryClient()

    const result = useMutation<TravelEntry[], Error, useUserTravelMutateVariables>(
        async ({ user_id, date, traveled}: useUserTravelMutateVariables) => {
            const { data } = await axios_backend.post(`/v1/users/travel/${user_id}/`, [{ 
                date: date_to_string(date), 
                traveled: traveled }]);
            return data.map((entry: any): TravelEntry => ({...entry, date: new Date(entry.date)}));
    }, {...options,
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries("travel_report_entries")
            
            if (options?.onSuccess != null) {
                options.onSuccess(data, variables, context);
            }
        }
    })    
    return result;
}


export const useUserTravelConfig = (user_id: number, options?: UseQueryOptions<UserTravelConfig[], Error>) => {
    return useQuery<UserTravelConfig[], Error>(["user_travel_configs", user_id], async () => {
        const { data } = await axios_backend.get(`/v1/users/${user_id}/travel_config/`);
        return data.map((entry: any): UserTravelConfig => ({...entry, date: new Date(entry.date)}));
    }, options)
}

type useUserTravelConfigMutationVariables = 
    {
        mutation_type: "add",
        user: User,
        date: Date,
        distance_km: number
    }
    | {
        mutation_type: "delete",
        user: User,
        config: UserTravelConfig
    }
    | {
        mutation_type: "edit",
        user: User,
        config: UserTravelConfig,
        date: Date,
        distance_km: number,
        fte: number
    }

export const useTravelConfigMutation = (options?: UseMutationOptions<UserTravelConfig | null, Error, useUserTravelConfigMutationVariables>) => {
    const queryClient = useQueryClient()

    const result = useMutation<UserTravelConfig, Error, useUserTravelConfigMutationVariables>(
        async (params: useUserTravelConfigMutationVariables) => {
            if (params.mutation_type === "add") {
                const { data } = await axios_backend.post(`/v1/travel/configs/`, {
                    user: params.user.url,
                    date:  strftime("%Y-%m-%d", params.date),
                    distance_km: params.distance_km
                });
                data.date =  new Date(data.date)
                return data;

            } else if (params.mutation_type === "delete") {
                await axios_backend.delete(params.config.url);

            } else if (params.mutation_type === "edit") {
                const { data } = await axios_backend.put(params.config.url, {
                    user: params.user.url,
                    date: strftime("%Y-%m-%d", params.date),
                    distance_km: params.distance_km,
                    fte: params.fte
                });
                data.date =  new Date(data.date)
                return data;
            }
    }, {
        ...options, 
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["user_travel_configs", variables.user.id])
            queryClient.invalidateQueries(["travel_report_overview"])

            if (options?.onSuccess != null) {
                options.onSuccess(data, variables, context);
            }
        }
    })    
    return result;
}

export const useTravelReport = (report_id: number, options?: UseQueryOptions<TravelReport, Error>) => {
    return useQuery<TravelReport, Error>(["travel_report", report_id], async () => {
        const { data } = await axios_backend.get(`/v1/travel/reports/${report_id}`);
        data.start_date = new Date(data.start_date);
        data.end_date = new Date(data.end_date);
        data.xlsx_url = (axios_backend.defaults.baseURL ?? "") + data.xlsx_url;
        return data;
    }, options)
}

export const useTravelReportEntries = (report_id: number, options?: UseQueryOptions<TravelEntry[], Error>) => {
    return useQuery<TravelEntry[], Error>(["travel_report_entries", report_id], async () => {
        const { data } = await axios_backend.get(`/v1/travel/reports/${report_id}/entries`);
        return data.map((entry: any): TravelEntry => ({...entry, date: new Date(entry.date)}));
    }, options)
}

export const useTravelReports = (options?: UseQueryOptions<TravelReport[], Error>) => {
    return useQuery<TravelReport[], Error>(["travel_reports"], async () => {
        let { data } = await axios_backend.get(`/v1/travel/reports/`);        
        data = data.map((entry: any): TravelReport => ({
            ...entry, 
            start_date: new Date(entry.start_date),
            end_date: new Date(entry.end_date),
            xlsx_url: (axios_backend.defaults.baseURL ?? "") + entry.xlsx_url,
        }));
        
        return data;
    }, options)
}

type useTravelReportsMutationVariables = 
    {
        mutation_type: "add",
        end_date: Date
    }
    | {
        mutation_type: "apply_all_updates",
        report: TravelReport
    }
    | {
        mutation_type: "edit",
        report: TravelReport,
        end_date: Date
    }
    | {
        mutation_type: "delete",
        report: TravelReport
    }
    | {
        mutation_type: "finalize",
        report: TravelReport
    }

export const useTravelReportsMutation = (options?: UseMutationOptions<TravelReport | null, Error, useTravelReportsMutationVariables>) => {
    const queryClient = useQueryClient()

    const result = useMutation<TravelReport, Error, useTravelReportsMutationVariables>(
        async (params: useTravelReportsMutationVariables) => {
            if (params.mutation_type === "add") {
                const { data } = await axios_backend.post(`/v1/travel/reports/`, {
                    end_date: strftime("%Y-%m-%d", params.end_date),
                });
                data.end_date =  new Date(data.end_date)
                return data;

            } else if (params.mutation_type === "apply_all_updates") {
                const { data } = await axios_backend.post(`${params.report.url}apply_all_updates/`)
                return data;

            } else if (params.mutation_type === "edit") {
                const { data } = await axios_backend.put(params.report.url, {
                    end_date: strftime("%Y-%m-%d", params.end_date)
                })
                return data;

            } else if (params.mutation_type === "delete") {
                await axios_backend.delete(params.report.url)
            
            } else if (params.mutation_type === "finalize") {
                const { data } = await axios_backend.post(`${params.report.url}finalize/`)
                return data;
            }

        }, {
            ...options, 
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["travel_reports"])

                if ("report" in variables) {
                    queryClient.invalidateQueries(["travel_report_entries", variables.report.id])
                    queryClient.invalidateQueries(["travel_report_overview", variables.report.id])
                    queryClient.invalidateQueries(["travel_report", variables.report.id])
                }
                
                if (options?.onSuccess != null) {
                    options.onSuccess(data, variables, context);
                }
            }
        })    
    return result;
}


export const useTravelReportOverview = (report: TravelReport, options?: UseQueryOptions<TravelReportOverview, Error>) => {
    return useQuery<TravelReportOverview, Error>(["travel_report_overview", report.id], async () => {
        const { data } = await axios_backend.get(`${report.url}report_overview/`);
        return data;
    }, options)
}
