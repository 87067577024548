import { useState, useEffect } from "react"
import { useUserTravel, useUserTravelMutation } from "../api_v1/travel"
import { Container, Row, Col, Table, Form, Spinner, Alert, Stack } from "react-bootstrap"
import { MonthSelect, MonthYear} from "../month_select/month_select"
import { match, select } from "ts-pattern"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import "./travel_table.scss"


export interface TravelTableProps {
    initial_month_year: MonthYear
    user_id: number
}

type TraveledStateScalar = undefined
                         | { state: "loading"} 
                         | { state: "error", detail: string } 
                         | { state: "ok", entry: { traveled: boolean, is_readonly: boolean } }

type TraveledState = {[key: string]: TraveledStateScalar};


export const TravelTable = ({initial_month_year, user_id}: TravelTableProps) => {
    // const user_context = useContext(UserContext);

    const [ dates, set_dates ] = useState<Date[]>(get_dates_in_month(initial_month_year));
    const [ traveled, set_traveled ] = useState<TraveledState>({})

    const month_changed = (month_year: MonthYear) => {
        set_dates(get_dates_in_month(month_year));        
    }

    const { isLoading, isError, error} = useUserTravel(
        user_id, dates[0], dates[dates.length - 1], {
            onSuccess: (user_travel) => {
                let new_traveled: TraveledState = {}; 
                dates.forEach((date) => {
                    new_traveled[date.toDateString()] = { state: "ok", entry: { traveled: false, is_readonly: false }};
                })

                for (const entry of user_travel) {
                    new_traveled[entry.date.toDateString()] = { state: "ok", entry: { traveled: entry.traveled, 
                                                                                    is_readonly: entry.is_readonly} };
                    
                }

                set_traveled(new_traveled);
            },
        })
    const { mutate } = useUserTravelMutation();

    useEffect(() => {
        if (isLoading) {
            let new_traveled: TraveledState = {};
            dates.forEach((date) => {
                new_traveled[date.toDateString()] = {state: "loading"};
            })
            set_traveled(new_traveled);
        }
    }, [dates, isLoading])

    if (isError && (error != null)) {
        return (
            <Alert variant="danger">
                <Alert.Heading>Error!</Alert.Heading>
                <p>{ error.message }</p>
            </Alert>
        )
    }

    const set_date_state = (date: Date, state: TraveledStateScalar) => {
        let new_traveled: TraveledState = {...traveled};
        new_traveled[date.toDateString()] = state
        set_traveled(new_traveled);
    }

    const handle_on_traveled_switch_changed = (event: React.ChangeEvent<HTMLInputElement>, date: Date) => {
        let value = event.target.checked;
        set_date_state(date, {state: "loading"});        

        mutate({user_id, date, traveled: value}, {
            onError: (error) => {
                set_date_state(date, {state: "error", detail: error.message})
            },
            onSuccess: (entries) => {
                for (const entry of entries) {
                    let new_traveled: TraveledState = {...traveled};
                    new_traveled[entry.date.toDateString()] = { state: "ok", entry: { traveled: entry.traveled, 
                                                                                      is_readonly: entry.is_readonly} };
                    set_traveled(new_traveled);
                }
            }
        })
    }

    return (
        <Container>
            <Row>
                <Col className="p-0">
                    <MonthSelect initial={initial_month_year} onChange={(value) => {month_changed(value)}}/>
                </Col>
            </Row>
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th>Traveled</th>
                        </tr>
                        { dates.map((date: Date) => (
                            <tr key={date.toDateString()} className={is_weekend(date) ? "travel-table-row-weekend" : ""}>
                                <th scope="row" className="travel-table-date-col">{date.toDateString()}</th>
                                <td className="travel-table-row-content">
                                    { match(traveled[date.toDateString()])
                                        .with({ state: "error", detail: select() }, (detail: string) => (
                                            <small className="text-danger">{ detail }</small>
                                        ))
                                        
                                        .with(undefined, () => (
                                            <Spinner size="sm" animation="border"/>
                                        ))

                                        .with({ state: "loading" }, () => (
                                            <Spinner size="sm" animation="border"/>
                                        ))

                                        .with( {state: "ok", entry: select()}, (entry: { traveled: boolean, 
                                                                                         is_readonly: boolean }) => (
                                            <Stack direction="horizontal">
                                                { entry.is_readonly ? 
                                                    <FontAwesomeIcon icon={faLock} className="me-2 text-muted"/> 
                                                : <></> }
                                                
                                                <Form.Check type="switch" 
                                                            checked={entry.traveled}
                                                            disabled={entry.is_readonly}
                                                            onChange={(event) => { handle_on_traveled_switch_changed(event, date) }}
                                                            />
                                            </Stack>                                            
                                        ))

                                        .exhaustive()
                                    }                                  
                                </td>
                            </tr>
                        ))}

                    </thead>
                </Table>
            </Row>
        </Container>
    )
}


const get_dates_in_month = (month_year: MonthYear): Date[] => {
    const month = month_year.month - 1;
    const date = new Date(month_year.year, month, 1);
    const dates: Date[] = [];

    while (date.getMonth() === month) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
}

const is_weekend = (date: Date): boolean => {
    return (date.getDay() === 0) || (date.getDay() === 6)
}