import { useContext, useState } from "react"
import { UserContext } from "../context"
import { ConfirmButton } from "../confirm_button/confirm_button"
import { Alert, Table, Button, Stack, Form, Row, Col, Spinner, Modal, Accordion } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { TravelConfigTable } from "../user_travel/travel_config_table"
import strftime from "strftime"

import { useTravelReportOverview, useTravelReportsMutation, useTravelReport, useTravelReportEntries } from "../api_v1/travel"
import { useUser } from "../api_v1/auth"
import { TravelReport } from "../types" 


export interface AdminReportProps {
    report: TravelReport,
}

export const AdminReport = ({report: report_param}: AdminReportProps) => {
    const user_context = useContext(UserContext);
    const { 
        isLoading: isOverviewLoading, 
        isError: isOverviewError,
        error: error_overview, 
        data: report_overview 
    } = useTravelReportOverview(report_param);
    
    const { 
        isLoading: isReportLoading, 
        isError: isReportError,
        isSuccess: isReportSuccess,
        error: error_report, 
        data: report 
    } = useTravelReport(report_param.id);

    const {
        isLoading: isEntriesLoading, 
        isError: isEntriesError,
        isSuccess: isEntriesSuccess,
        error: error_report_entries, 
        data: report_entries
    } = useTravelReportEntries(report_param.id)
    
    const { mutate } = useTravelReportsMutation();

    const isLoading = isOverviewLoading || isReportLoading || isEntriesLoading;
    const isError = isOverviewError || isReportError || isEntriesError;

    const [ selected_user_id, set_selected_user_id ] = useState<number | null>(null)

    const {
        data: selected_user,
    } = useUser(selected_user_id)

    if (isError) {
        return (
            <Alert variant="danger">
                <Alert.Heading>Error!</Alert.Heading>
                {isOverviewError ? <p>{ error_overview?.message }</p> : <></>}
                {isReportError ? <p>{ error_report?.message }</p> : <></>}
                {isEntriesError ? <p>{ error_report_entries?.message }</p> : <></>}
            </Alert>
        )
    }

    if (isLoading || (!isReportSuccess)) {
        return <div className="text-center"><Spinner animation="border"></Spinner></div>;
    }


    const go_to_report_list = () => {        
        user_context.onGotoPage({page: "admin", sub: "travel_reports"})
    }

    const apply_all_updates = () => {
        if ((!isReportSuccess) || (report == null)) return;

        mutate({mutation_type: "apply_all_updates", report})
    }

    const delete_report = () => {
        if ((!isReportSuccess) || (report == null)) return;
        mutate({mutation_type: "delete", report}, {
            onSuccess: go_to_report_list
        })
    }

    const finalize = () => {
        if ((!isReportSuccess) || (report == null)) return;
        mutate({mutation_type: "finalize", report})
    }

    const handle_on_date_change = (event: any) => {
        if ((!isReportSuccess) || (report == null)) return;
        const end_date = new Date(event.target.value);
        mutate({mutation_type: "edit", report, end_date})
    }
    
    console.log(report);

    return (
        <>
            <Modal show={selected_user != null} onHide={() => set_selected_user_id(null)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{ selected_user?.first_name } { selected_user?.last_name }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { isEntriesSuccess && (selected_user != null) && (report_entries != null)?
                        <>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>User travel configuration</Accordion.Header>
                                    <Accordion.Body>
                                        <TravelConfigTable user_id={selected_user.id}/>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { report_entries.map((entry) => (
                                        (entry.traveled || entry.is_updated) && (entry.user_id === selected_user.id) ? 
                                            <tr>
                                                <th>
                                                    { entry.is_updated ? 
                                                        entry.traveled ?
                                                            <span className="text-success">
                                                                <FontAwesomeIcon icon={faPlus}/>&nbsp;  
                                                                { entry.date.toDateString() }
                                                            </span>
                                                        :
                                                            <span className="text-danger">
                                                                <FontAwesomeIcon icon={faMinus}/>&nbsp;  
                                                                { entry.date.toDateString() }
                                                            </span> 
                                                    : entry.date.toDateString() }                                                   
                                                </th>
                                            </tr>                                            
                                        : <></>                                     
                                    ))}
                                    
                                </tbody>
                            </Table>
                        </> : <></> }
                </Modal.Body>
            </Modal>
            { report != null ?
                <Stack>            
                    <div>
                        <Stack direction="horizontal" gap={1} className="mb-3" style={{width: "100%"}}>
                            <Button onClick={go_to_report_list}>Back</Button>
                            <Button onClick={apply_all_updates}>Apply all updates</Button>
                            <Button onClick={finalize} 
                                    disabled={ (report.updated_count > 0) || (report.state === "finalized")}>
                                Finalize
                            </Button>
                            <Button href={report.xlsx_url}>Download</Button>
                            <ConfirmButton variant="danger" onConfirm={delete_report}>Delete</ConfirmButton>
                        </Stack>
                    </div>
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                Start date
                            </Form.Label>
                            <Col sm="auto">
                                <Form.Control type="date" value={strftime("%Y-%m-%d", report.start_date)} disabled/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                End date
                            </Form.Label>
                            <Col sm="auto">
                                <Form.Control type="date" value={strftime("%Y-%m-%d", report.end_date)} onChange={handle_on_date_change}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                State
                            </Form.Label>
                            <Col sm="auto">
                                <Form.Control disabled readOnly defaultValue={report.state} />
                            </Col>
                        </Form.Group>
                    </Form>

                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Days traveled / Expected</th>
                                <th>Compensation / Expected</th>
                                <th>Has updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            { report_overview?.user_reports.map((user_report) => (
                                <tr className="admin-travel-reports-row" onClick={() => { set_selected_user_id(user_report.user.id) }}>
                                    <td>
                                        {user_report.user.first_name} {user_report.user.last_name}
                                        { user_report.missing_travel_config ? 
                                            <small className="text-danger">
                                                <br/><FontAwesomeIcon icon={faExclamationTriangle}/>&nbsp;
                                                Missing configuration
                                            </small>
                                        : <></> }
                                        
                                    </td>
                                    <td> 
                                        { user_report.missing_travel_config ?
                                            user_report.linked_travel_days_count

                                        : user_report.linked_travel_days_count > user_report.estimated_travel_days ?
                                            <span className= "text-danger fw-bold">
                                                <FontAwesomeIcon icon={faExclamationTriangle}/> &nbsp;
                                                {user_report.linked_travel_days_count}/{user_report.estimated_travel_days.toFixed(1)}
                                            </span>

                                        : <>{user_report.linked_travel_days_count}/{user_report.estimated_travel_days.toFixed(1)}</>
                                    }
                                    </td>

                                    <td> 
                                        { user_report.missing_travel_config ?
                                            user_report.cost

                                        : user_report.cost > user_report.estimated_cost ?
                                            <span className= "text-danger fw-bold">
                                                <FontAwesomeIcon icon={faExclamationTriangle}/> &nbsp;
                                                €{user_report.cost.toFixed(2)}/€{user_report.estimated_cost.toFixed(2)}
                                            </span>

                                        : <>€{user_report.cost.toFixed(2)}/€{user_report.estimated_cost.toFixed(2)}</>
                                    }
                                    </td>

                                    <td>{user_report.has_updates ? 
                                        <span className="text-danger fw-bold">
                                            <FontAwesomeIcon icon={faExclamationTriangle}/> &nbsp;
                                            { user_report.updated_travel_days_count }
                                        </span>
                                    : ""}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Stack>
            : <></> }
            
        </>
    )
}