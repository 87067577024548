import { useContext } from "react"
import { Nav, NavDropdown } from "react-bootstrap"
import { UserContext } from "../context"
import { Login } from "../auth/login"

interface NavBarUserItemProps  {
}

export const NavBarUserItem = (props: NavBarUserItemProps) => {
    const user_context = useContext(UserContext);

    return (
        user_context.user ? (
            <NavDropdown title={user_context.user.first_name} id="basic-nav-dropdown" className="ms-auto">
                <NavDropdown.Item disabled>Profile</NavDropdown.Item>
                <NavDropdown.Item onClick={user_context.onUserLogout}>Log out</NavDropdown.Item>
            </NavDropdown>
        ) : (
            <Login/>
        )
    )
};
