import { useContext, useState } from "react"
import { Form, Button, Modal, Alert, Spinner } from "react-bootstrap"
import { UserContext } from "../context"
import { useUserRegistration } from "../api_v1/auth"

interface RegisterProps  {
}

export const Register = (props: RegisterProps) => {
    const [show, set_show] = useState(false);
    const user_context = useContext(UserContext);

    const { isLoading, error, mutate } = useUserRegistration({
        onSuccess: () => {
            set_show(false);
            user_context.onAlert({
                content: (
                    <>
                        <Alert.Heading>Registration succesfull</Alert.Heading>
                        <p>Please log in to start using the HR portal</p>
                    </>                        
                ), 
                variant: "success"
            })
        }
    })

    const on_sumbit = (event: React.FormEvent) => {
        event.preventDefault();
        const target = event.target as typeof event.target & {
            username: { value: string };
            email: { value: string };
            password: { value: string };
            password2: { value: string };
            first_name: { value: string };
            last_name: { value: string };
        };

        mutate({
            username: target.email.value, 
            email: target.email.value, 
            password: target.password.value,                                      
            password2: target.password2.value, 
            first_name: target.first_name.value, 
            last_name: target.last_name.value
        })
    }

    return (
        <>
            <Button size="lg" onClick={() => set_show(true)} className="float-end">Register</Button>

            <Modal show={show} onHide={() => set_show(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Register</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={on_sumbit}>
                        <Form.Group className="mb-3">
                            <Form.Label>First name</Form.Label>
                            <Form.Control name="first_name" 
                                          type="text"
                                          isInvalid={ error?.first_name !== undefined }/>
                            <Form.Control.Feedback type="invalid">
                                { error?.first_name?.map((msg) => <span>{msg}<br/></span>)}
                            </Form.Control.Feedback>                       
                        </Form.Group>
                        
                        <Form.Group className="mb-3">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control name="last_name" 
                                          type="text"
                                          isInvalid={error?.last_name !== undefined}/>
                            <Form.Control.Feedback type="invalid">
                                { error?.last_name?.map((msg) => <span>{msg}<br/></span>)}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control name="email" 
                                        type="email" 
                                        placeholder="name@example.com" 
                                        isInvalid={error?.email !== undefined}/>
                            <Form.Control.Feedback type="invalid">
                                { error?.email?.map((msg) => <span>{msg}<br/></span>)}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control name="password" type="password" isInvalid={error?.password !== undefined}/>
                            <Form.Control.Feedback type="invalid">
                                { error?.password?.map((msg) => <span>{msg}<br/></span>)}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control name="password2" type="password" isInvalid={error?.password !== undefined}/>
                            <Form.Control.Feedback type="invalid">
                                { error?.password?.map((msg) => <span>{msg}<br/></span>)}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Text className="small text-danger">
                            { error?.message }
                        </Form.Text>

                        { isLoading ?
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        :
                            <Button type="submit" className="float-end">Register</Button>
                        }
                        
                    </Form>
                </Modal.Body>
            </Modal>

            
        </>
        
    )
};
