import { useContext, useState } from "react"
import { Form, Button, Nav, Modal, Spinner } from "react-bootstrap"
import { UserContext } from "../context"
import { useUserLogin } from "../api_v1/auth"

interface LoginProps  {
}

export const Login = (props: LoginProps) => {
    const user_context = useContext(UserContext);


    const { isLoading, error, mutate } = useUserLogin({
        onSuccess: user_context.onUserLogin
    })

    const [show, set_show] = useState(false);

    const on_sumbit = (event: React.FormEvent) => {
        event.preventDefault();
        const target = event.target as typeof event.target & {
            email: { value: string };
            password: { value: string };
        };

        mutate({email: target.email.value, password: target.password.value})
    }

    return (
        <>
            <Nav.Item className="ms-auto">
                <Nav.Link onClick={() => set_show(true)}>
                    Login
                </Nav.Link>
            </Nav.Item>

            <Modal show={show} onHide={() => set_show(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={on_sumbit}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email</Form.Label>
                            <Form.Control name="email" 
                                        type="email" 
                                        placeholder="name@example.com" 
                                        isInvalid={error?.email !== undefined}/>
                            <Form.Control.Feedback type="invalid">
                                { error?.email?.map((msg) => <span>{msg}<br/></span>)}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Password</Form.Label>
                            <Form.Control name="password" type="password" isInvalid={error?.password !== undefined}/>
                            <Form.Control.Feedback type="invalid">
                                { error?.password?.map((msg) => <span>{msg}<br/></span>)}
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Text className="small text-danger">
                            { error?.message }
                        </Form.Text>

                        { isLoading ?
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        : 
                            <Button type="submit" className="float-end">Login</Button>
                        }                        
                    </Form>
                </Modal.Body>
            </Modal>

            
        </>
        
    )
};
