import './app.scss';
import { HRPortal } from "./hr_portal/hr_portal"
import { QueryClient, QueryClientProvider } from 'react-query'
 

const queryClient = new QueryClient()


function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <HRPortal/>
        </QueryClientProvider>
    )
}

export default App;
