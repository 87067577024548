import { createContext } from 'react';
import { UserContextType } from "./types"

export const null_user_context: UserContextType = {
    user: null,
    api_tokens: null,
    onUserLogin: ()=>{},
    onUserLogout: ()=>{},
    onTokenRefresh: ()=>{},
    onAlert: ()=>{},
    onGotoPage: ()=>{}
}

export const UserContext = createContext<UserContextType>(null_user_context);
