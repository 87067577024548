import { useState, useContext, useEffect } from "react";
import { UserContext } from "../context"
import { User } from "../types"
import { useUser } from "../api_v1/auth"
import { Form, Alert, Col, Row, Spinner, Stack, Accordion } from "react-bootstrap"
import { TravelTable, } from "./travel_table"
import { TravelConfigTable } from "./travel_config_table"
import { Month } from "../month_select/month_select"

interface UserTravelProps  {
    user_id: number | null
}


export const UserTravel = ({ user_id }: UserTravelProps) => {
    const { isLoading, isError, error, data: user} = useUser(user_id)

    const current_date = new Date();
    const initial_month_year = {
        month: current_date.getMonth() + 1 as Month,
        year: current_date.getFullYear()
    }

    if (isLoading) {
        return <div className="text-center"><Spinner animation="border"></Spinner></div>;
    }

    if (isError && (error != null)) {
        return (
            <Alert variant="danger">
                <Alert.Heading>Error!</Alert.Heading>
                <p>{ error.message }</p>
            </Alert>
        )
    }

    if (user == null) {
        return <Alert variant="info">No user selected</Alert>
    } 

    return (
        <Stack>
            <Form>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                        Name
                    </Form.Label>
                    <Col sm="auto">
                        {`${user.first_name} ${user.last_name}`}
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                        Email
                    </Form.Label>
                    <Col sm="auto">
                        {user.email}
                    </Col>
                </Form.Group>
            </Form>            

            <Accordion className="mx-0 mb-2">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Configuration</Accordion.Header>
                    <Accordion.Body>
                        <TravelConfigTable user_id={user.id}/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <TravelTable initial_month_year={initial_month_year} user_id={user.id}/>
        </Stack>     
            
    );
};
